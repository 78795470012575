import React, { useEffect, useState } from "react";
import axios from "axios";
// components
import { Track } from "../components";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Navigation]);

const Tracks = ({ fetchVideo, staredMusic, setStaredMusic }) => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    await axios
      .get("/data/tracks.json", {
        headers: {},
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const capitalize = ([first, ...rest]) =>
    `${first.toUpperCase()}${rest.join("")}`;

  return (
    <div className="h-full w-full pb-[30%] lg:pb-[10%] pt-5 overflow-scroll p-3">
      {data.map((data, index) => (
        <div
          className="flex h-auto flex-wrap overflow-hidden my-3 mt-5"
          key={index}
        >
          <div className="flex items-center justify-center flex-col h-auto">
            <img
              src={`/img/sounds/${data.title}.png`}
              alt={data.title}
              className="h-[70px]"
              style={{ width: 90 }}
            />
            <h3 className="font-bold">{capitalize(data.title)}</h3>
          </div>
          <Swiper navigation={true} className="mySwiper" breakpoints={{
            576: {
            slidesPerView:1,
            },
            768: {
            slidesPerView:2,
            },
            1200: {
            slidesPerView:3,
            },
          }}>
            {data.data.map((track, index) => (
              <SwiperSlide>
                <Track
                  videoId={track.id}
                  name={track.name}
                  track={track}
                  key={index}
                  fetchVideo={fetchVideo}
                  staredMusic={staredMusic}
                  setStaredMusic={setStaredMusic}
                  showInOneLine={false}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ))}
    </div>
  );
};

export default Tracks;
