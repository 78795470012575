import React from "react";
import { Button, Slider, Tooltip } from "@material-ui/core"; // material-ui

// icons
import {
  BiTransfer,
  BiVolume,
  BiVolumeFull,
  BiVolumeLow,
  BiVolumeMute,
} from "react-icons/bi";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";
import { FiChevronRight } from "react-icons/fi";
import { BsMusicNoteList } from "react-icons/bs";
import data from "../data";

// type writer animation
import { Typewriter } from "react-typewriting-effect";
import "react-typewriting-effect/dist/index.css";

// react router
import { Link } from "react-router-dom";

// import screenfull from "screenfull";
// import { useHotkeys } from "react-hotkeys-hook";
import toast from "react-hot-toast";
// import screenfull from "screenfull";

const LofiPlayer = ({
  paused,
  setPaused,
  volume,
  setVolume,
  name,
  fetchVideo,
  muted,
  buffering,
  toggleMute,
  setIsFullScreen,
  isFullScreen,
  setBgGif,
  todoInserting,
  setTodoInserting,
}) => {
  // random video
  const updatetoRandomVideo = () => {
    const randomVideo = data[Math.floor(Math.random() * data.length)];
    if (typeof window !== undefined) {
      const { id, name } = randomVideo;

      const video = {
        videoId: id,
        name: name,
      };
      localStorage.setItem("video", JSON.stringify(video));
    }
    fetchVideo();
  };

  // previous video
  const moveToPreviousVideo = () => {
    if (typeof window !== undefined) {
      const currentVideo = data.filter((video) => video.name === name);
      console.log("here");
      console.log(data.findIndex(0));
      const currentVideoIndex = currentVideo[0].index;
      if (currentVideoIndex === 0) {
        toast.error("No More Previous Video");
      } else {
        const { id, name } = data[currentVideoIndex - 1];

        const video = {
          videoId: id,
          name: name,
        };
        localStorage.setItem("video", JSON.stringify(video));
      }
    }
    fetchVideo();
  };

  // next video
  const moveToNextVideo = () => {
    if (typeof window !== undefined) {
      const currentVideo = data.filter((video) => video.name === name);
      console.log(currentVideo);
      const currentVideoIndex = currentVideo[0].index;
      if (currentVideoIndex === data.length - 1) {
        toast.error("No More Next Video");
      } else {
        const { id, name } = data[currentVideoIndex + 1];

        const video = {
          videoId: id,
          name: name,
        };
        localStorage.setItem("video", JSON.stringify(video));
      }
    }
    fetchVideo();
  };

  // keyboard shortcuts for productivity
  document.onkeypress = function (e) {
    toggleMute();
    checkShortcuts(e);
  };
  document.onkeydown = function (e) {
    toggleMute();
    checkKeyDowns(e);
  };
  document.addEventListener("click", toggleMute);

  const checkShortcuts = (e) => {
    const { keyCode } = e;

    if (keyCode === 32 && !todoInserting) {
      setPaused(!paused);
    }
  };

  // useHotkeys("f", () => {
  //   setIsFullScreen(!isFullScreen);
  //   screenfull.toggle();
  // });

  const checkKeyDowns = (e) => {
    const { keyCode } = e;

    if (keyCode === 37) {
      moveToPreviousVideo();
    } else if (keyCode === 39) {
      moveToNextVideo();
    } else if (keyCode === 38) {
      const nextVolume = parseInt(volume / 10, 10) * 10;
      if (nextVolume < 100) {
        if (nextVolume % 10 === 0) {
          setVolume(nextVolume + 10);
        }
      }
    } else if (keyCode === 40) {
      const nextVolume = parseInt(volume / 10, 10) * 10;
      if (nextVolume > 0) {
        if (nextVolume % 10 === 0) {
          setVolume(nextVolume - 10);
        }
      }
    }
  };

  return (
    <div id={"lofiplayer"} className={"noborder"}>
      <div className="w-full pl-7">
        {muted ? (
          <Typewriter
            string="Press any key to Start..."
            className="text-xl text-white"
            delay={80}
          />
        ) : (
          <>
            <div className="flex animate__animated animate__fadeInUp"
            style={{alignItems: "center"}}>
              <div className="flex">
                <div
                  className="relative rounded-lg flex items-center justify-center bg-gradient-to-t from-[#674DB4] to-[#A34FA7] overflow-hidden h-10 w-10 shadow-2xl"
                  onClick={() => setPaused(!paused)}
                >
                  <Tooltip title={paused ? "paused" : "playing"} arrow>
                    <Button className="playBtn">
                      <div className={`control-btn ${!paused && "paused"}`}>
                        <label htmlFor="playpause"></label>
                      </div>
                    </Button>
                  </Tooltip>
                </div>
                <Tooltip title="random" arrow>
                  <div
                    className="relative rounded-lg flex items-center justify-center text-white overflow-hidden h-10 w-8 shadow-2xl ml-1"
                    onClick={updatetoRandomVideo}
                  >
                    <Button className="playBtn h-full">
                      <BiTransfer className="text-2xl text-white" />
                    </Button>
                  </div>
                </Tooltip>
                <Link to="/tracks">
                  <Tooltip title="Tracks" arrow>
                    <div className="relative rounded-lg flex items-center justify-center text-white overflow-hidden h-10 w-8 shadow-2xl">
                      <Button className="playBtn h-full">
                        <BsMusicNoteList className="text-2xl text-white" />
                      </Button>
                    </div>
                  </Tooltip>
                </Link>
                <div
                  className="relative rounded-lg flex items-center justify-center text-white overflow-hidden h-10 w-8 shadow-2xl ml-1"
                  onClick={moveToPreviousVideo}
                >
                  <Tooltip title="Previous" arrow>
                    <Button className="playBtn h-full">
                      <HiChevronDoubleLeft className="text-2xl text-white" />
                    </Button>
                  </Tooltip>
                </div>
                <div
                  className="relative rounded-lg flex items-center justify-center text-white overflow-hidden h-10 w-8 shadow-2xl"
                  onClick={moveToNextVideo}
                >
                  <Tooltip title="Next" arrow>
                    <Button className="playBtn h-full">
                      <HiChevronDoubleRight className="text-2xl text-white" />
                    </Button>
                  </Tooltip>
                </div>
              </div>

              <Tooltip title={`Volume ${volume}%`} arrow>
                <div className="w-[50%] lg:w-40 ml-1 flex items-center justify-center mt-0">
                  <div className="mr-2">
                    {volume < 1 ? (
                      <BiVolumeMute className="text-2xl text-white" />
                    ) : volume > 85 ? (
                      <BiVolumeFull className="text-2xl text-white" />
                    ) : volume < 45 ? (
                      <BiVolume className="text-2xl text-white" />
                    ) : (
                      <BiVolumeLow className="text-2xl text-white" />
                    )}
                  </div>

                  <Slider
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                    value={volume}
                    onChange={(e, volume) => setVolume(volume)}
                  />
                </div>
              </Tooltip>
            </div>

            <div className="flex items-start justify-start w-auto mt-2 animate__animated animate__fadeInUp">
              {!paused ? (
                <div className="spectrum flex items-end justify-end h-5 mr-2">
                  <div className="line1 bg-white line"></div>
                  <div className="line2 bg-white line"></div>
                  <div className="line3 bg-white line"></div>
                  <div className="line4 bg-white line"></div>
                </div>
              ) : (
                <div className="spectrum flex items-end justify-end h-5 mr-2">
                  <div className="h-[.2em] bg-white line"></div>
                  <div className="h-[.2em] bg-white line"></div>
                  <div className="h-[.2em] bg-white line"></div>
                  <div className="h-[.2em] bg-white line"></div>
                </div>
              )}

              <Link
                to="/tracks"
                className="flex items-center justify-start w-auto"
              >
                <div className="flex items-center justify-start">
                  <h1 className="text-[#A34FA7]">
                    {buffering
                      ? "Buffering up ..."
                      : paused
                      ? "Music paused"
                      : "Click to Change"}
                  </h1>
                  <FiChevronRight className="text-xl text-[#A34FA7]" />
                </div>
                <Tooltip
                  className="text-white"
                  title={`${name} - Click to change`}
                  arrow
                >
                  <h1>{name}</h1>
                </Tooltip>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LofiPlayer;
