import sounds from "../sounds";
import toast from "react-hot-toast";
import React, { useState } from "react";
import { Slider } from "@material-ui/core";
import { getRandomGif } from "../helper/RandomImageCalls";
import { Button } from "@material-ui/core";
import { AiOutlineSound } from 'react-icons/ai';
import { GiStopSign } from "react-icons/gi";
import {BsQuestion} from "react-icons/bs";

//Sounds.js IS FOR THE BACKGROUND AMBIENT SOUND PAGE
const Sounds = ({setBgGif }) => {
  const [volumes, setVolumes] = useState({
    Rain: 100,
    Thunderstorm: 100,
    Snow: 100,
    Campfire: 100,
    Birds: 100,
    Rowing: 100,
    Wind: 100,
    Leaves: 100,
    Cafe: 100,
    Farm: 100,
    Keyboard: 100,
    Ocean: 100,
    Library: 100,
    River: 100,
    Night: 100,
    Road: 100,
    Fan: 100,
    Windchime: 100,
    Train: 100,
    Cat: 100
  });

  const playSound = (name) => {
    var audio = document.getElementById(name);
    const img = document.querySelector(`.${name}-image`);
    const slider = document.querySelector(`.${name}-slider`);

    if (audio.paused) {
      toast.loading(`Loading ${name} 🎵`); /// loading state

      audio.play();
      img.style.opacity = 1;
      slider.style.opacity = 1;
      setBgGif(`/img/sounds/${name}.gif`);

      setTimeout(() => {
        toast.remove();
      }, [1000]);
    } else {
      audio.pause();
      setTimeout(() => {
        toast.remove();
      }, [1000]);


      img.style.opacity = 0.35;
      slider.style.opacity = 0;
      setBgGif(getRandomGif());
    }
  };

  const playButton = (data) => {
    const newVolumes = Object.assign({}, volumes);

    data.forEach(sound => {
      const { name, volume } = sound;
      var audio = document.getElementById(name);
      const img = document.querySelector(`.${name}-image`);
      const slider = document.querySelector(`.${name}-slider`);

      if (audio.paused) {
        toast.loading(`Loading ${name} 🎵`); /// loading state

        audio.play();
        img.style.opacity = 1;
        slider.style.opacity = 1;
        audio.volume = volume / 100;
        newVolumes[name] = volume;
        setBgGif(`/img/sounds/${name}.gif`);

        setTimeout(() => {
          toast.remove();
        }, [1000]);
      } else {
        audio.pause();
        setTimeout(() => {
          toast.remove();
        }, [1000]);
        newVolumes[name] = 100;
        img.style.opacity = 0.35;
        slider.style.opacity = 0;
        setBgGif(getRandomGif());
      }
    });
    setVolumes(newVolumes);
  };

  const stopButton = (data) => {
    data.forEach((name) => {
      var audio = document.getElementById(name);
      const img = document.querySelector(`.${name}-image`);
      const slider = document.querySelector(`.${name}-slider`);

        audio.pause();
        setTimeout(() => {
        toast.remove();
        }, [1000]);
        img.style.opacity = 0.35;
        slider.style.opacity = 0;
        setBgGif(getRandomGif());
    });
  };

  return (
    <div id = {'sounds'} className={"h-full w-full pb-[30%] lg:pb-[5%] overflow-scroll sounds"}>
      <div style={{marginTop: "40px", marginBottom:"50px"}} className="flex justify-center w-full my-3">
        <Button
          className="cursor-pointer overflow-hidden !rounded-none !p-0 !m-0 !min-w-0 !min-h-0 !capitalize !mx-1"
          onClick= {() => playButton([
            { name: "Rain", volume: 50 },
            { name: "Thunderstorm", volume: 20 },
            { name: "Campfire", volume: 90 },
            { name: "Library", volume: 20 },
            { name: "Keyboard", volume: 15 }
          ])}
        >
          <div className="border border-[#674DB4] p-2 pr-4 rounded-md flex items-center">
            <img src="/img/sounds/focus.png" alt="" className="h-[30px] ml-3" />
            Focus
          </div>
        </Button>
        <Button
          className="cursor-pointer overflow-hidden !rounded-none !p-0 !m-0 !min-w-0 !min-h-0 !capitalize !mx-1"
          onClick={() => playButton([
            { name: "Campfire", volume: 70 },
            { name: "Night", volume: 10 },
            { name: "Rain", volume: 75 },
            { name: "Birds", volume: 25 }
          ])}
        >
          <div className="border border-[#674DB4] p-2 pr-4 rounded-md flex items-center">
            <img src="/img/sounds/relax.png" alt="" className="h-[30px] ml-3" />
            Relax
          </div>
        </Button>
        <Button
          className="cursor-pointer overflow-hidden !rounded-none !p-0 !m-0 !min-w-0 !min-h-0 !capitalize !mx-1"
          onClick={() => playButton([
            { name: "Rain", volume: 70 },
            { name: "Night", volume: 20 },
            { name: "Cat", volume: 20 },
            { name: "Campfire", volume: 15 }
          ])}
        >
          <div className="border border-[#674DB4] p-2 pr-4 rounded-md flex items-center">
            <img src="/img/sounds/sleep.png" alt="" className="h-[30px] ml-3" />
            Sleep
          </div>
        </Button>
        <Button
          className="cursor-pointer overflow-hidden !rounded-none !p-0 !m-0 !min-w-0 !min-h-0 !capitalize !mx-1"
          onClick={() => playButton([
            { name: "River", volume: 25 },
            { name: "Wind", volume: 25 },
            { name: "Windchime", volume: 20 },
            { name: "Leaves", volume: 35 }
          ])} //set to right playButtons
        >
          <div className="border border-[#674DB4] p-2 pr-4 rounded-md flex items-center">
            <img src="/img/sounds/meditate.png" alt="" className="h-[30px] ml-3" />
            Meditate
          </div>
        </Button>

        <Button
          className="cursor-pointer overflow-hidden !rounded-none !p-0 !m-0 !min-w-0 !min-h-0 !capitalize !mx-1"
          // onClick={() => pauseAllSound(sounds)}
          onClick={() => stopButton(["Rain", "Thunderstorm", "Snow", "Campfire", "Birds", "Rowing", "Wind", "Leaves", "Cafe",
        "Farm", "Keyboard", "Ocean", "Library", "River", "Night", "Road", "Fan", "Windchime", "Train", "Cat"])}
        >
          <div className="border border-[#674DB4] p-2 pr-4 rounded-md flex items-center">
            <div className="h-[30px] ml-3">
              <GiStopSign className="stop_icon"/>
            </div>
            Stop
          </div>
        </Button>
      </div>
      <div className="flex flex-wrap w-full items-center justify-center relative">
        {sounds.map((sound) => (
          <div
            data-sound={sound.name}
            key={sound.id}
            className="m-3 cursor-pointer relative"
          >
            <audio id={sound.name} preload="none" loop>
              <source
                src={
                  sound.soundLink
                    ? sound.soundLink
                    : `/sounds/${sound.name}.mp3`
                }
                alt="play"
                type="audio/mp3"
              />
            </audio>
            <div className="mood-image">
              <img
                src={`/img/sounds/${sound.name}.gif`}
                onClick={() => playSound(sound.name)}
                width="250"
                style={{
                  height: "150px",
                }}
                className={`opacity-[.35] relative rounded-md ${sound.name}-image`}
                alt={sound.name}
                title={sound.name}
              />
              <h1 className="absolute top-0 left-0 ml-2 text-md font-light mt-1 opacity-[.85]">
                {sound.name}
              </h1>
            </div>
            <div className={`w-[250px] ${sound.name}-slider opacity-0 flex justify-center items-center`}>
              <AiOutlineSound className="sounds-icon mr-1"/>
              <Slider
                min={0}
                max={100}
                valueLabelDisplay="auto"
                value={volumes[sound.name]}
                onChange={(e, volume) => {
                  document.getElementById(sound.name).volume = volume / 100;
                  const newVolumes = Object.assign({}, volumes);
                  newVolumes[sound.name] = volume;
                  setVolumes(newVolumes);
                }}
              />
            </div>
          </div>
        ))}
      </div>
        {/* POWR.io Popup */}
        <div className="powr-popup" id="bb4b230d_1639278819"> </div>
    </div>
  );
};

export default Sounds;
