export const animeRec = [

    {
        group:"Anime To Cheer You Up",
        groupId:"anime_to_cheer_you_up",
        nextPointer:'.anime_to_cheer_you_up .swiper-button-next',
        prevPointer:'.anime_to_cheer_you_up .swiper-button-prev',
        animes:[
            {
                id: 0,
                title: "Sweetness & Lightning",
                fileName: "SweetnessLightning",
                link: "https://www.crunchyroll.com/sweetness-lightning"
            },
            {
                id: 1,
                title: "Tanaka-kun is Always Listless",
                fileName: "Tanakakun",
                link: "https://www.crunchyroll.com/tanaka-kun-is-always-listless"
            },
            {
                id: 2,
                title: "My Rommate is a Cat",
                fileName: "RoommateCat",
                link: "https://www.crunchyroll.com/my-roommate-is-a-cat"
            },
            {
                id: 3,
                title: "Laid-Back Camp",
                fileName: "LaidBackCamp",
                link: "https://www.crunchyroll.com/laid-back-camp"
            },
            {
                id: 4,
                title: "Barakamon",
                fileName: "Barakamon",
                link: "https://www.funimation.com/shows/barakamon/"
            },
            {
                id: 5,
                title: "Miss Kobayashi's Dragon Maid",
                fileName: "DragonMaid",
                link: "https://www.crunchyroll.com/miss-kobayashis-dragon-maid"
            },
            {
                id: 6,
                title: "A Place Further Than the Universe",
                fileName: "PlaceFurtherThanUniverse",
                link: "https://www.crunchyroll.com/a-place-further-than-the-universe"
            },
            {
                id: 7,
                title: "K-ON!",
                fileName: "KON",
                link: "https://www.crunchyroll.com/k-on"
            },
            {
                id: 8,
                title: "Flying Witch",
                fileName: "FlyingWitch",
                link: "https://www.crunchyroll.com/flying-witch"
            },
        ]
    },

    {
        group:"Anime For When You Need A Cry",
        groupId:"anime_for_when_you_need_a_cry",
        nextPointer:'.anime_for_when_you_need_a_cry .swiper-button-next',
        prevPointer:'.anime_for_when_you_need_a_cry .swiper-button-prev',
        animes:[
            {
                id: 9,
                title: "Wolf Children",
                fileName: "WolfChildren",
                link: "https://www.funimation.com/shows/wolf-children/"
            },
            {
                id: 10,
                title: "Plastic Memories",
                fileName: "PlasticMemories",
                link: "https://www.crunchyroll.com/plastic-memories"
            },
            {
                id: 11,
                title: "Kotaro Lives Alone",
                fileName: "KotaroLivesAlone",
                link: "https://www.netflix.com/title/81275353"
            },
            {
                id: 12,
                title: "Clannad",
                fileName: "Clannad",
                link: "https://www.funimation.com/shows/clannad/"
            },
            {
                id: 13,
                title: "Anohana: The Flower We Saw That Day",
                fileName: "AnohanaTheFlowerWeSawThatDay",
                link: "https://www.crunchyroll.com/anohana-the-flower-we-saw-that-day"
            },
            {
                id: 14,
                title: "To Your Eternity",
                fileName: "ToYourEternity",
                link: "https://www.crunchyroll.com/to-your-eternity"
            },
        ]
    },

    {
        group:"Anime To Pump You Up",
        groupId:"anime_to_pump_you_up",
        nextPointer:'.anime_to_pump_you_up .swiper-button-next',
        prevPointer:'.anime_to_pump_you_up .swiper-button-prev',
        animes:[
            {
                id: 15,
                title: "One-Punch Man",
                fileName: "OnePunchMan",
                link: "https://www.crunchyroll.com/one-punch-man"
            },
            {
                id: 16,
                title: "How Heavy Are the Dumbbells You Lift?",
                fileName: "HowHeavyAretheDumbbellsYouLift",
                link: "https://www.funimation.com/shows/how-heavy-are-the-dumbbells-you-lift/"
            },
            {
                id: 17,
                title: "Haikyu!!",
                fileName: "Haikyu",
                link: "https://www.crunchyroll.com/haikyu"
            },
            {
                id: 18,
                title: "Hyouka",
                fileName: "Hyouka",
                link: "https://www.crunchyroll.com/hyouka"
            },
            {
                id: 19,
                title: "Hajime No Ippo: The Fighting!",
                fileName: "HajimeNoIppoTheFighting",
                link: "https://www.crunchyroll.com/hajime-no-ippo-the-fighting"
            },
            {
                id: 20,
                title: "My Hero Academia",
                fileName: "MyHeroAcademia",
                link: "https://www.crunchyroll.com/my-hero-academia"
            },
            {
                id: 21,
                title: "Kuroko's Basketball",
                fileName: "KurokosBasketball",
                link: "https://www.crunchyroll.com/kurokos-basketball"
            },
        ]
    },

    {
        group:"Anime That Will Make You Laugh",
        groupId:"anime_that_will_make_you_laugh",
        nextPointer:'.anime_that_will_make_you_laugh .swiper-button-next',
        prevPointer:'.anime_that_will_make_you_laugh .swiper-button-prev',
        animes:[
            {
                id: 22,
                title: "Asobi Asobase",
                fileName: "AsobiAsobase",
                link: "https://www.crunchyroll.com/asobi-asobase-workshop-of-fun-"
            },
            {
                id: 23,
                title: "The Disastrous Life of Saiki K.",
                fileName: "TheDisastrousLifeofSaikiK",
                link: "https://www.netflix.com/title/80117781"
            },
            {
                id: 24,
                title: "Ghost Stories",
                fileName: "GhostStories",
                link: "https://www.amazon.com/Ghost-Stories/dp/B08MB9G1LZ"
            },
            {
                id: 25,
                title: "Nichijou - My Ordinary Life",
                fileName: "Nichijou",
                link: "https://www.funimation.com/shows/nichijou-my-ordinary-life/"
            },
            {
                id: 26,
                title: "Pop Team Epic",
                fileName: "PopTeamEpic",
                link: "https://www.netflix.com/title/80228291"
            },
            {
                id: 27,
                title: "Ouran High School Host Club",
                fileName: "OuranHighSchoolHostClub",
                link: "https://www.netflix.com/title/70205014"
            },
            {
                id: 28,
                title: "Gintama",
                fileName: "Gintama",
                link: "https://www.crunchyroll.com/gintama"
            },
            {
                id: 29,
                title: "The Devil is a Part-Timer!",
                fileName: "TheDevilisaPartTimer",
                link: "https://www.hulu.com/series/the-devil-is-a-part-timer-8534ee83-b681-4dbc-bad9-b9a1d3f08483"
            },
        ]
    },

    {
        group:"Anime With Beautiful Music & Scenery To Lose Yourself In",
        groupId:"anime_with_beautiful_music",
        nextPointer:'.anime_with_beautiful_music .swiper-button-next',
        prevPointer:'.anime_with_beautiful_music .swiper-button-prev',
        animes:[
            {
                id: 30,
                title: "Kino's Journey -the Beautiful World- the Animated Series",
                fileName: "KinoJourney",
                link: "https://www.crunchyroll.com/kinos-journey-the-beautiful-world-the-animated-series"
            },
            {
                id: 31,
                title: "Maquia: When The Promised Flower Blooms",
                fileName: "Maquia",
                link: "https://www.amazon.com/Maquia-When-Promised-Flower-Blooms/dp/B096CH422P"
            },
            {
                id: 32,
                title: "The Ancient Magus' Bride",
                fileName: "TheAncientMagusBride",
                link: "https://www.crunchyroll.com/the-ancient-magus-bride"
            },

            {
                id: 33,
                title: "Violet Evergarden",
                fileName: "VioletEvergarden",
                link: "https://www.netflix.com/title/80182123"
            },
            {
                id: 34,
                title: "The Garden of Words",
                fileName: "TheGardenofWords",
                link: "https://www.netflix.com/id-en/title/70291121"
            },
            {
                id: 35,
                title: "Your Name.",
                fileName: "YourName",
                link: "https://www.funimation.com/shows/your-name/"
            },

            {
                id: 36,
                title: "MUSHI-SHI",
                fileName: "MUSHISHI",
                link: "https://www.crunchyroll.com/mushi-shi"
            },
            {
                id: 37,
                title: "MADE IN ABYSS",
                fileName: "MADEINABYSS",
                link: "https://www.amazon.com/MADE-IN-ABYSS-Season-1/dp/B073S1Y7BZ"
            },
        ]
    },

    {
        group:"Anime That Will Make You Hungry",
        groupId:"anime_that_will_make_you_hungry",
        nextPointer:'.anime_that_will_make_you_hungry .swiper-button-next',
        prevPointer:'.anime_that_will_make_you_hungry .swiper-button-prev',
        animes:[
            {
                id: 38,
                title: "True Cooking Master Boy",
                fileName: "TrueCookingMasterBoy",
                link: "https://www.crunchyroll.com/true-cooking-master-boy"
            },

            {
                id: 39,
                title: "Yakitate!! Japan",
                fileName: "Yakitate",
                link: "https://www.funimation.com/shows/yakitate-japan/"
            },
            {
                id: 40,
                title: "GOURMET GIRL GRAFFITI",
                fileName: "GOURMETGIRLGRAFFITI",
                link: "https://www.hidive.com/tv/gourmet-girl-graffiti"
            },
            {
                id: 41,
                title: "Food Wars! Shokugeki no Soma",
                fileName: "FoodWars",
                link: "https://www.crunchyroll.com/food-wars-shokugeki-no-soma"
            },
            {
                id: 42,
                title: "Restaurant to Another World",
                fileName: "RestaurantAnotherWorld",
                link: "https://www.crunchyroll.com/restaurant-to-another-world"
            },

            {
                id: 43,
                title: "Ms. Koizumi Loves Ramen Noodles",
                fileName: "MsKoizumiLovesRamenNoodles",
                link: "https://www.crunchyroll.com/ms-koizumi-loves-ramen-noodles"
            },
        ]
    },

    {
        group:"Anime With Mental Health Themes",
        groupId:"anime_with_mental_health_themes",
        nextPointer:'.anime_with_mental_health_themes .swiper-button-next',
        prevPointer:'.anime_with_mental_health_themes .swiper-button-prev',
        animes:[
            {
                id: 44,
                title: "Orange",
                fileName: "Orange",
                link: "https://www.funimation.com/shows/orange/"
            },
            {
                id: 45,
                title: "Welcome to the N-H-K",
                fileName: "NHK",
                link: "https://www.funimation.com/shows/welcome-to-the-n-h-k/"
            },
            {
                id: 46,
                title: "WATAMOTE ~No Matter How I Look at It, It's You Guys' Fault I'm Not Popular!",
                fileName: "WATAMOTE",
                link: "https://www.crunchyroll.com/watamote-no-matter-how-i-look-at-it-its-you-guys-fault-im-not-popular/more"
            },

            {
                id: 47,
                title: "A Silent Voice",
                fileName: "SilentVoice",
                link: "https://www.netflix.com/title/80223226"
            },
            {
                id: 48,
                title: "March comes in like a lion",
                fileName: "Marchcomesinlikealion",
                link: "https://www.crunchyroll.com/march-comes-in-like-a-lion"
            },
            {
                id: 49,
                title: "Your lie in April",
                fileName: "YourlieinApril",
                link: "https://www.crunchyroll.com/your-lie-in-april"
            },
            {
                id: 50,
                title: "Yuri!!! on ICE",
                fileName: "Yuri",
                link: "https://www.crunchyroll.com/yuri-on-ice"
            },
        ]
    },


];