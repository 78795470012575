import React from "react";

// material-ui
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

// icons
import { FiChevronDown } from "react-icons/fi";

const TabWrapper = ({ children, name, expanded, handleChange, panelNo }) => {
  return (
    <div className="w-8/12 bg-gradient p-[1px] rounded-[4px] my-[5px] bg-[#111] border border-[#674DB4] relative">
      <div className="w-full">
        <Accordion
          style={{
            background: "inherit",
          }}
          expanded={expanded === panelNo}
          onChange={handleChange(panelNo)}
        >
          <AccordionSummary
            expandIcon={<FiChevronDown className="text-[#fafafa]" />}
            style={{
              borderBottom: "1px solid",
              background: "inherit",
            }}
            className="!border-[#444]"
          >
            <h1 className="font-semibold text-[#fafafa]">{name}</h1>
          </AccordionSummary>
          <AccordionDetails>
            <div className="w-full py-1 mt-2 text-[#ccc] font-normal">
              {children}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default TabWrapper;
