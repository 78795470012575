import { Button, Tooltip } from "@material-ui/core";
import React from "react";
import { BsCheckBox, BsSquare, BsTrash } from "react-icons/bs";

const Todo = ({ todo, deleteTodo, markAsCompleted }) => {
  return (
    <div className="w-full relative p-3 flex items-center justify-between bg-gradient-to-l from-[#000] to-[#11111198] border border-[#674DB490] rounded-md mt-5 animate__animated animate__fadeInUp">
      <div className="relative flex items-center justify-start">
        <div
          className={`absolute duration-500 bg-green-400 h-1 ${
            todo.completed ? "w-full" : "w-[0px]"
          }`}
        ></div>
        <h2 className="text-lg lg:text-xl font-medium">{todo.name}</h2>
      </div>
      <div className="flex">
        <Button
          className="cursor-pointer overflow-hidden !rounded-none !p-0 !m-0 !min-w-0 !min-h-0 !capitalize !mx-1"
          onClick={() => markAsCompleted(todo.id)}
        >
          <Tooltip title={todo.completed ? "Completed" : "Not Completed"} arrow>
            <div className="flex items-center justify-center text-lg lg:text-xl text-green-600 hover:text-green-400 duration-300">
              {todo.completed ? (
                <BsCheckBox className="ml-1" />
              ) : (
                <BsSquare className="ml-1 text-sm" />
              )}
            </div>
          </Tooltip>
        </Button>
        <Button
          className="cursor-pointer overflow-hidden !rounded-none !p-0 !m-0 !min-w-0 !min-h-0 !capitalize !mx-1"
          onClick={() => deleteTodo(todo.id)}
        >
          <Tooltip title={todo.completed ? "Delete" : "Delete"} arrow>
            <div className="flex items-center justify-center text-lg lg:text-xl text-red-600 hover:text-red-400 duration-300">
              <BsTrash className="ml-1" />
            </div>
          </Tooltip>
        </Button>
      </div>
    </div>
  );
};

export default Todo;
