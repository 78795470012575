import React, { useState } from "react";

// material design
import { TextField } from "@material-ui/core";

// uuid for getting ids
import { v4 as uuidv4 } from "uuid";

// components
import { Todo } from "../components";

// useLocalStorage hook
import useLocalStorage from "../util/useLocalStorage";
import Pomodoro from "./Pomodoro";

const TodoList = (
    {
      fetchVideo,
      todoInserting,
      setTodoInserting,
    }
) => {
  const [todo, setTodo] = useState("");
  const [todos, setTodos] = useLocalStorage("todos", []);

  // adding todo
  const AddNewTodo = (e) => {


    if (todo) {
      // checking enter
      if (e.keyCode === 13) {
        // creating an obj
        const todoObj = { id: uuidv4(), name: todo, completed: false };

        if(!todoInserting){
          setTodoInserting(!todoInserting)
        }
        setTodos([...todos, todoObj]);
        setTodo("");

      }
    }
  };

  // deleting todo
  const deleteTodo = (id) => {
    setTodos(todos.filter((todo) => todo.id !== id));
  };

  // mark as completed
  const markAsCompleted = (id) => {
    setTodos(
      todos.map((todo) =>
        todo.id === id ? { ...todo, completed: !todo.completed } : todo
      )
    );
  };
  const focusOnInput = () => {
  if(!todoInserting){
      setTodoInserting(!todoInserting)
    }

  }
  const blurFromInput = () => {

    if(todoInserting){
      setTodoInserting(!todoInserting)
    }
  }

  return (
    <div className="flex items-center justify-center h-full w-full">
      <Pomodoro />
      <div className="h-full w-[55%] overflow-y-scroll px-3 mt-6">
        <div className="w-full mt-5 p-2 flex items-center justify-between bg-gradient-to-r from-[#674DB4]  to-[#A34FA7] border border-[#674DB4] rounded-md mb-4 animate__animated animate__fadeInDown">
          <div className="w-full h-full rounded-md shadow-2xl">
            <TextField
              label="Todo"
              variant="filled"
              className="w-full bg-white rounded-md shadow-5xl"
              value={todo}
              onChange={(e) => setTodo(e.target.value)}
              onKeyDown={(e) => AddNewTodo(e)}
              onBlur={blurFromInput}
              onFocus={focusOnInput}
            />
          </div>
        </div>
        <div className="">
          {todos.map((todo, index) => (
            <Todo
              todo={todo}
              deleteTodo={deleteTodo}
              markAsCompleted={markAsCompleted}
              key={index}
            />
          ))}
        </div>
      </div>
        {/* POWR.io Popup */}
        <div className="powr-popup" id="bcdd7a6a_1639286583"></div>
    </div>
  );
};

export default TodoList;
