import React, { useEffect, useState } from "react";

import { Button } from "@material-ui/core"; // material-ui

// react router
import { useHistory } from "react-router-dom";
import { BsStar, BsStarFill } from "react-icons/bs";

const Track = ({
  videoId,
  name,
  fetchVideo,
  staredMusic,
  setStaredMusic,
  track,
  showInOneLine,
}) => {
  const [isStared, setIsStared] = useState("");
  let history = useHistory();

  const updateVideo = () => {
    if (typeof window !== undefined) {
      const video = {
        videoId: videoId,
        name: name,
        type:"music"
      };
      localStorage.setItem("video", JSON.stringify(video));
    }
      document.getElementById('lofiplayer').style.display = 'block'
    
    fetchVideo();
    history.push("/"); // redirects
  };

  const addToStaredMusic = () => {
    if (typeof window !== undefined) {
      if (isStared) {
        setStaredMusic(
          staredMusic.filter((music) => music.videoId !== videoId)
        );
      } else {
        const video = {
          videoId: videoId,
          name: name,
          type:"music"
        };
        setStaredMusic([...staredMusic, video]);
      }
    }
  };

  const fetchStaredMusic = () => {
    if (staredMusic.some((music) => music.videoId === videoId)) {
      setIsStared(true);
    } else {
      setIsStared(false);
    }
  };

  useEffect(() => {
    fetchStaredMusic();
  });

  return (
    <div className="w-[250px] md:w-[200px] lg:w-[250px] relative rounded-lg flex items-center justify-center w-auto my-1 shadow-2xl overflow-hidden animate__animated animate__fadeInUp ">
      <Button className="track">
        <div
          className="absolute top-0 rounded-sm right-[2px] z-10 bg-[#333] p-[6px] border border-[#666] text-[#ccc] hover:text-[#fff]"
          onClick={addToStaredMusic}>
          {isStared ? (
            <BsStarFill className="text-lg" />
          ) : (
            <BsStar className="text-lg" />
          )}
        </div>
        <img 
          src={
            // track.customThumbnail
              `/img/thumbnails/${name}.jpg`
              // remove false statement to get custom thumbnail to show in stared music section
              // : `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`
          }
          alt={name}
          className="rounded-md h-[125px] w-[250px]"
          onClick={updateVideo}
        />
        <h1 className={showInOneLine?"break-normal text-md font-light mt-1 w-[250px] text-center line-hidden":"break-normal text-md font-light mt-1 w-[100%] text-center"} onClick={updateVideo}>
          {name}
        </h1>
      </Button>
    </div>
  );
};

export default Track;
