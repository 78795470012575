import React from "react";

// components
import { Track } from "../components";
import Anime from "../components/Anime";


const StaredMusic = ({ fetchVideo, staredMusic, setStaredMusic }) => {
    const isTrack=(type)=>{
        if(type=="music"){
            return true
        }
        return false
    }

  return (
    <div className="block h-full items-center justify-start flex-wrap flex-col overflow-scroll pb-[30%] lg:pb-[10%] relative">
      <h1 className="text-4xl font-bold text-center mt-3 animate__animated animate__fadeInDown">
        Your Playlist
      </h1>
      {staredMusic.length < 1 && (
        <p className="text-thin mt-3 text-center">
          You haven't saved any music.
        </p>
      )}
      <div className="flex items-center justify-center flex-wrap w-full mt-3">
        {staredMusic.map((track, index) => (
            track.type=="music"?
          <Track
            videoId={track.videoId}
            name={track.name}
            key={index}
            track={track}
            fetchVideo={fetchVideo}
            staredMusic={staredMusic}
            setStaredMusic={setStaredMusic}
            showInOneLine={true}
          />:
            <Anime
                animeId={track.videoId}
                title={track.name}
                fileName={track.fileName}
                animeLink={track.animeLink}
                staredMusic={staredMusic}
                setStaredMusic={setStaredMusic}
                fetchVideo={fetchVideo}
                showInOneLine={true}
            ></Anime>
        ))}
      </div>
    </div>
  );
};

export default StaredMusic;
