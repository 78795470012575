import React, { useEffect } from "react";

// components
import { Header } from "./components";
import { useHotkeys } from "react-hotkeys-hook";
import { useHistory } from "react-router";
import { Toaster } from "react-hot-toast";
import { getRandomGif } from "./helper/RandomImageCalls";
import { Button } from "@material-ui/core";
import useLocalStorage from "./util/useLocalStorage";

const Base = ({ children, isConferenceApp, isFullScreen, setIsFullScreen, bgGif, setBgGif }) => {
  const [acceptCookies, setAcceptCookies] = useLocalStorage("cookies", false);
  let history = useHistory();
  useHotkeys("alt+f", () => history.push("/faq"));
  useHotkeys("alt+a", () => history.push("/anime-self-care-kit"));
  useHotkeys("alt+r", () => history.push("/mental-health-resources"));
  useHotkeys("alt+t", () => history.push("/tracks"));
  useHotkeys("alt+c", () => history.push("/chat"));
  useHotkeys("alt+m", () => history.push("/mood-mix"));
  useHotkeys("alt+l", () => history.push("/focus"));
  useHotkeys("alt+s", () => history.push("/stared-music"));
  useHotkeys("alt+g", () => setBgGif(getRandomGif()));
  useHotkeys("f", () => history.push("/"));

  // Find a Therapist Iframe
  useEffect(() => {
    if(!isConferenceApp) {
      window.qwarySettings = {
        surveyId : '3Y6A066rNaDrV17TDvQBN1xYmgwm4o8Y2HDFwOdtvbQ=',
        popupHeight : '500px',
        popupWidth : '650px',
        layout : 'popup',
        buttonText : 'Find a Therapist',
        showLunchButton: true,
        removeWidget: true,
        removeWidgetOnClose: true,
        buttonBackgroundColor : 'rgb(190, 135, 242)',
        buttonColor : 'rgb(255, 255, 255)',
        qwaryBranding: false,
        logo: 'https://www.animeforhumanity.org/wp-content/uploads/2022/04/AT-1024x1024.png',
      };
      window.qwary.survey();
    }
  })

  // Beta Tester Iframe
  useEffect(() => {
    if(isConferenceApp) {
      window.qwarySettings = {
        surveyId : '3Y6A066rNaDrV17TDvQBN1xYmgwm4o8Yd2Znf3c7bx0=',
        popupHeight : '500px',
        popupWidth : '650px',
        layout : 'popup',
        buttonText : 'Become a Beta Tester',
        showLunchButton: true,
        removeWidget: true,
        removeWidgetOnClose: true,
        buttonBackgroundColor : 'rgb(190, 135, 242)',
        buttonColor : 'rgb(255, 255, 255)',
        qwaryBranding: false,
      };
      window.qwary.survey();
    }
  })

  // Discord IFrame
  useEffect(() => {
    if(!isConferenceApp) {
      new window.Crate({
        server: '376083774653464586', // Anime For Humanity
        channel: '602231504386523174' // 🛑╏rules
      })
    }
  })

  // Feedback IFrame
  useEffect(() => {
    if(isConferenceApp) {
      const sleek = document.getElementById("sleek-button");
      if(sleek) {
        sleek.remove();
      }
    }
  })


  return (
    <div className="h-screen text-[#F0E9E2] bg-cover  w-full relative">
      <div
        className="relative flex flex-col items-center justify-center h-screen w-screen
        bg-center random-bg bg-cover"
        style={{
          background: `url("${bgGif}")`,
          backgroundSize: "cover",
        }}
      >
        {!acceptCookies && (
          <div className="flex items-center justify-between bg-gradient-to-r from-[#674DB4] to-[#A34FA7] rounded-md animate__animated animate__fadeInDown z-[10] absolute bottom-5 right-4 p-[2px] w-[400px]">
            <div className="p-4 rounded-[5px] bg-[#111] text-sm">
              We uses cookies to provide you with a great user experience.
              <a
                href="https://www.animeforhumanity.org/privacy-policy/"
                className="underline font-semibold ml-1"
              >
                Learn more
              </a>
              <br />
              <Button
                className="cursor-pointer overflow-hidden !rounded-none !p-0 !m-0 !min-w-0 !min-h-0 !capitalize !mt-3"
                onClick={() => setAcceptCookies(true)}
              >
                <div className="bg-gradient-to-r from-[#674DB4] to-[#A34FA7] p-2 px-5 text-xs rounded-md flex items-center">
                  Accept
                </div>
              </Button>
            </div>
          </div>
        )}
        <div className="vignette"></div>
        <div
          id="bigcontainer"
          className="relative h-[90%] w-[90%] rounded-xl overflow-hidden shadow-2xl bg-cover bg-center border-2 border-[#674DB4] shadow-5xl"
          style={{
            background: `linear-gradient(to bottom, rgba(10,10,10,.7),rgba(0,0,0,1)), url("${bgGif}")`,
            backgroundSize: "cover",
          }}
        >
          <div className="lines-animation"></div>
          <Header
            isConferenceApp={isConferenceApp}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
          />
          {children}
        </div>
        <h4 id='projectby' className="text-left w-[90%] mt-1">
          Project by
          <a
            href="https://twitter.com/anime4humanity"
            target="_blank"
            rel="noreferrer"
            className="ml-1 hover:underline"
          >
            Anime for Humanity
          </a>
        </h4>
      </div>
      <Toaster position="bottom-right" />
    </div>
  );
};

export default Base;
