import React from "react";

const KeyboardShortcuts = () => {
  const keyboardShortcutsArr = [
    { keystroke: "Spacebar", command: "Pause/Play" },
    { keystroke: "Up/Down", command: "Raise/Lower Volume" },
    { keystroke: "Left/Right", command: "Next/Previous Track" },
    { keystroke: "Alt + F", command: "FAQ" },
    { keystroke: "Alt + A", command: "Anime Self Care Library" },
    { keystroke: "Alt + T", command: "View All Tracks" },
    { keystroke: "Alt + R", command: "Mental Health Resources" },
    { keystroke: "Alt + M", command: "Mood Mix" },
    { keystroke: "Alt + L", command: "Todo/Pomodoro" },
    { keystroke: "Alt + S", command: "Go to Stared Music" },
    { keystroke: "Alt + G", command: "Change Background GIF" },
    { keystroke: "F", command: "Enter Full Screen" },
  ];

  const Shortcut = ({ keystroke, command }) => {
    return (
      <div className="p-4 m-2 h-[80px] relative flex w-full lg:w-3/12 items-center bg-gradient-to-l from-[#000] to-[#11111198] border border-[#674DB490] rounded-md mt-2 animate__animated animate__fadeInUp">
        <kbd>{keystroke}</kbd>
        <p className="mx-1">-</p>
        <h3 className="font-normal text-sm"> {command}</h3>
      </div>
    );
  };

  return (
    <div className="flex items-center justify-center flex-col text-[#FAFAFA]">
      <div className="animate__animated animate__fadeInDown">
        <p style={{ margin: "100px" }} className="text-sm font-light">
          Here are the keyboard shortcuts to enhance your productivity.
        </p>
      </div>
      
      <div
        className="flex items-center justify-center w-12/12 flex-wrap mt-3 overflow-y-scroll h-screen lg:h-auto"
        style={{ paddingBottom: "82%" }}
      >
        {keyboardShortcutsArr.map((shortcut) => {
          return (
            <Shortcut
              keystroke={shortcut.keystroke}
              command={shortcut.command}
            />
          );
        })}
      </div>
    </div>
  );
};

export default KeyboardShortcuts;
