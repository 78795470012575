let data = [
  {
    index: 0,
    id: "5qap5aO4i9A",
    name: "Lofi Hip Hop Radio Deep Focus/Study Beats",
  },
  {
    index: 1,
    id: "GNWLILeztaI",
    name: "Anime Themed Lofi",
  },
  {
    index: 2,
    id: "78f2L3P3TOM",
    name: "Anime opening",
  },
  {
    index: 3,
    id: "nwe-lOqwicE",
    name: "Anime Jazz",
  },
  {
    index: 4,
    id: "hj83cwfOF3Y",
    name: "Soft Classical Piano",
    customThumbnail: true,
  },
  {
    index: 5,
    id: "Efqha5ZQSZRg",
    name: "Anime piano",
    customThumbnail: true,
  },
  {
    index: 6,
    id: "3jWRrafhO7M",
    name: "Relaxing Studio Ghibli Music",
    customThumbnail: true,
  },
  {
    index: 7,
    id: "QrISuOl5hHE",
    name: "Anime Piano & Violin",
    customThumbnail: true,
  },
  {
    index: 8,
    id: "DWcJFNfaw9c",
    name: "Lofi Beats to sleep",
  },
  {
    index: 9,
    id: "NIkQW3lZJu4",
    name: "Anime Music Box",
    customThumbnail: true,
  },
  {
    index: 10,
    id: "fNh2yB0w8gU",
    name: "Deep Sleep Ambient",
  },
  {
    index: 11,
    id: "8xWOvcEf6Y4",
    name: "White Noise",
  },
  {
    index: 12,
    id: "tT1cWUSo-DA",
    name: "Haikyu Guided Meditation",
  },
   {
    index: 13,
    id: "qL_M8wWspDY",
    name: "Demon Slayer Guided Meditation",
  },
   {
    index: 14,
    id: "runLP6GBvLo",
    name: "Attack On Titan Guided Meditation",
  },
   {
    index: 15,
    id: "Sn0nYFEodYg",
    name: "Jujutsu Kaisen Guided Meditation",
  },
];

export default data;
