import React, { useEffect, useState } from "react";

// react router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//used to page redirection
import { Redirect } from 'react-router';

// base
import Base from "./Base";

// animate.css
import "animate.css/animate.css";

// components & core
import { YTwrapper } from "./components";
import {
  Home,
  ConferenceHome,
  KeyboardShortcuts,
  Pomodoro,
  Sounds,
  StaredMusic,
  TodoList,
  Tracks,
  AnimeRecommendations,
  LofiPlayer,
  AnimeTherapy,
} from "./core";

// utils
import useLocalStorage from "./util/useLocalStorage";

// helper calls
import { getRandomGif } from "./helper/RandomImageCalls";
import Faq from "./core/Faq";
import Kits from "./core/Kits";
import { PlayerButton } from "./components";
import { FaqButton } from "./components";

const Routes = () => {
  // lofi video player properties
  const [paused, setPaused] = useState(false);
  const [volume, setVolume] = useLocalStorage("volume", 50);
  const [video, setVideo] = useState([]);
  const [muted, setMuted] = useState(true);
  const [buffering, setBuffering] = useState(false);
  // General
  const isConferenceApp = process.env.REACT_APP_IS_CONFERENCE_APP == "true" || false;
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [bgGif, setBgGif] = useState("");
  const [todoInserting, setTodoInserting] = useState(false);

  // playlist
  const [staredMusic, setStaredMusic] = useLocalStorage("staredMusic", []);

  // destructing video
  const { id, name } = video;

  // most important part
  const fetchVideo = () => {
    if (typeof window !== undefined) {
      const video = localStorage.getItem("video");
      const parsedVideo = JSON.parse(video);

      setVideo({
        id: parsedVideo ? parsedVideo.videoId : "7NOSDKb0HlU",
        name: parsedVideo
          ? parsedVideo.name
          : "lofi hip hop radio - beats to relax/study to",
      });
      if(paused){
        setPaused(!paused);
      }
    }
  };

  const toggleMute = () => {
    setMuted(false);
  };

  // all the events
  useEffect(() => {
    fetchVideo();
    setBgGif(getRandomGif());
  }, []);

  const onBuffering = () => {
    setBuffering(true);
  };
  const onPlaying = () => {
    setBuffering(false);
  };

  // routing for all routes
  return (
    <Router >
      <div className=" screen custom-cursor" id="content-desktop" >

        <Base
          isConferenceApp={isConferenceApp}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          bgGif={bgGif}
          setBgGif={setBgGif}
        >
          <div className="absolute top-0 left-0 invisible -z-10" >
            {id && (
              <YTwrapper
                videoId={id}
                paused={paused}
                volume={volume / 100}
                muted={muted}
                onBuffering={onBuffering}
                onPlaying={onPlaying}
              />
            )}
          </div>

          <Sounds setBgGif={setBgGif} />

          <Switch>
            <Route path="/" exact>
              { !isConferenceApp ? <Home/> : <ConferenceHome/> }

            </Route>
            <Route path="/tracks" exact>
              <Tracks
                fetchVideo={fetchVideo}
                staredMusic={staredMusic}
                setStaredMusic={setStaredMusic}
              />
            </Route>
            <Route path="/kind-anime-for-unkind-days" exact>
              <AnimeRecommendations
                  staredMusic={staredMusic}
                  setStaredMusic={setStaredMusic}
                  fetchVideo={fetchVideo}/>
            </Route>
            <Route path="/focus" exact>
              <TodoList fetchVideo={fetchVideo}
                        todoInserting={todoInserting}
                        setTodoInserting={setTodoInserting}/>
            </Route>
            <Route path="/anime-self-care-kit" exact>
              <Kits />
            </Route>
            <Route path="/faq" exact>
              <Faq />
            </Route>
            <Route path="/mood-mix" exact>
            </Route>
            <Route path="/pomodoro" exact>
              <Pomodoro
                  />
            </Route>
            <Route path="/mental-health-resources" exact>
              <AnimeTherapy />
            </Route>
            <Route path="/keyboard-shortcuts" exact>
              <KeyboardShortcuts />
            </Route>
            <Route path="/stared-music" exact>
              <StaredMusic
                fetchVideo={fetchVideo}
                staredMusic={staredMusic}
                setStaredMusic={setStaredMusic}
              />
            </Route>
            <Route path="*" >
              <Redirect to="/" />
            </Route>
          </Switch>

        </Base>
        <PlayerButton>
          <LofiPlayer className='withBorder'
            setPaused={setPaused}
            paused={paused}
            volume={volume}
            setVolume={setVolume}
            name={name}
            fetchVideo={fetchVideo}
            muted={muted}
            buffering={buffering}
            toggleMute={toggleMute}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            setBgGif={setBgGif}
            todoInserting={todoInserting}
            setTodoInserting={setTodoInserting}
          />
        </PlayerButton>
        <Route path={[ "/mood-mix", "/kind-anime-for-unkind-days", "/focus"]} exact>
          <FaqButton id='faqbutton' />
        </Route>
      </div>

    </Router>
  );
};

export default Routes;
