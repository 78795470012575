import React, { useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsStar, BsStarFill } from "react-icons/bs";
import Anime from "./Anime";
SwiperCore.use([Navigation]);

const AnimeSwiper = ({
  staredMusic,
  setStaredMusic,
  fetchVideo,
  animes,
  navigations,
  swiperName,
}) => {
  const [isStared, setIsStared] = useState("");
  return (
    <div className={`${swiperName} all_slide`}>
      <div className="swiper-button-next"></div>

      <Swiper
        navigation={navigations}
        className="anime-swiper h-[350px] "
        sp
        breakpoints={{
          // when window width is >= 768px
          768: {
            width: 610,
            slidesPerView: 2,
            slidePerGroup: 2,
          },
          // when window width is >= 768px
          1024: {
            width: 1072,
            slidesPerView: 4,
            slidePerGroup: 4,
          },
        }}
      >
        {animes.map((recommand) => (
          <SwiperSlide>
            <Anime
              animeId={recommand.id}
              title={recommand.title}
              fileName={recommand.fileName}
              animeLink={recommand.link}
              staredMusic={staredMusic}
              setStaredMusic={setStaredMusic}
              fetchVideo={fetchVideo}
              showInOneLine={true}
            ></Anime>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="swiper-button-prev "></div>
    </div>
  );
};
export default AnimeSwiper;
