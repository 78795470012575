import React, { useCallback, useEffect, useState } from "react";

// material design
import { Button, Tooltip } from "@material-ui/core";

// icons
import {
  BsArrowsFullscreen,
  BsCheckBox,
  BsFullscreenExit,
  BsStar,
  BsQuestionCircle,
  BsDisplay,
} from "react-icons/bs";
import { HiOutlineBookOpen }  from "react-icons/hi";
import { FiTwitter } from "react-icons/fi";
import { GoKeyboard } from "react-icons/go";
import { SiAudiomack } from "react-icons/si";
import { CgHeadset } from "react-icons/cg";
import { FiPhone } from "react-icons/fi";
// components
import { Logo } from ".";

import { Link, useLocation } from "react-router-dom"; // react router

// fullscreen api
import screenfull from "screenfull";
import { useHotkeys } from "react-hotkeys-hook";

const Header = ({ isConferenceApp, isFullScreen, setIsFullScreen }) => {
  const classname =
    "relative h-[90%] w-[90%] rounded-xl overflow-hidden animate__animated animate__fadeInDown shadow-2xl bg-cover bg-center border-2 border-[#674DB4] shadow-5xl";
  document.fullscreenEnabled =
    document.fullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.documentElement.webkitRequestFullScreen;

  const [pathname, setPathname] = useState("");
  // eslint-disable-next-line
  const location = useLocation();
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        if (!isFullScreen) {
          console.log("pressed esc");
          setIsFullScreen(!isFullScreen);
          document.getElementById("sounds").style.display = "none";
          document.getElementById("bigcontainer").className = classname;
          document.getElementById("bigcontainer").style.top = "0%";
          document.getElementById("bigcontainer").style.height = "";
          document.getElementById("projectby").style.opacity = "100";
        }
        console.log("pressed esc");
      }
    },
    [isFullScreen, setIsFullScreen]
  );

  useEffect(() => {
    setPathname(
      window.location.pathname.substring(1)
        ? capitalize(window.location.pathname.substring(1).replace(/-/g, " "))
        : "Anime Self Care"
    );
    document.addEventListener("keydown", escFunction);
    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [location, escFunction]);

  const capitalize = (str) => {
    if (str === "anime self care kit") {
      return "Anime Self Care Kit";
    } else {
      return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
  };

  useHotkeys(
    "f",
    () => {
      if (!isFullScreen) {
        if (screenfull.isEnabled) {
          screenfull.request();
        }
        setIsFullScreen(!isFullScreen);
        document.getElementById("bigcontainer").className =
          "absolute h-[8%] w-[90%] rounded-xl animate__animated animate__fadeInDown shadow-2xl bg-cover bg-center border-2 border-[#674DB4] shadow-5xl bigcontainer";
        document.getElementById("bigcontainer").style.top = "9.5%";
        document.getElementById("projectby").style.opacity = "0";
        document.getElementById("bigcontainer").style.height = "80px";
        document.getElementById("trackslink").style.display = "none";
        document.getElementById("moodmixlink").style.display = "none";
        document.getElementById("animelink").style.display = "none";
        document.getElementById("focuslink").style.display = "none";
        document.getElementById("selfcarelink").style.display = "none";
        document.getElementById("staredlink").style.display = "none";
        document.getElementById("keyboardlink").style.display = "none";
        document.getElementById("faqlink").style.display = "none";
      } else {
        setIsFullScreen(!isFullScreen);
        document.exitFullscreen();
        setIsFullScreen(false);
        document.getElementById("bigcontainer").className = classname;
        document.getElementById("bigcontainer").style.top = "0%";
        document.getElementById("bigcontainer").style.height = "";
        document.getElementById("projectby").style.opacity = "100";
        document.getElementById("trackslink").style.display = "block";
        document.getElementById("moodmixlink").style.display = "block";
        document.getElementById("animelink").style.display = "block";
        document.getElementById("focuslink").style.display = "block";
        document.getElementById("selfcarelink").style.display = "block";
        document.getElementById("staredlink").style.display = "block";
        document.getElementById("keyboardlink").style.display = "block";
        document.getElementById("faqlink").style.display = "block";
      }
    },
    [isFullScreen, setIsFullScreen]
  );

  const twitterLink =
    "https://twitter.com/intent/tweet?text=Check out http://AnimeSelfCare.org your place for calm, focus, and productivity using anime 🎧 %0A%0AEnjoy some relaxing tunes 🎶 practice self-care with anime and create your perfect sound environment for work, sleep, and meditation 🧘%0A%0AProject by @anime4humanity.";

  
  const homeLink = (
    <Link to="/" className="flex mt-2 cursor-pointer">
      <Logo cursor-pointer />
      <div
        className="flex flex-col items-start justify-center"
        onClick={() => {
          document.getElementById("sounds").style.display = "none";
          document.getElementById("lofiplayer").style.display = "block";
          if (
            !isFullScreen &&
            document.URL.substr(document.URL.length - 1) === "/"
          ) {
            document.getElementById("bigcontainer").className =
              "absolute h-[8%] w-[90%] rounded-xl animate__animated animate__fadeInDown shadow-2xl bg-cover bg-center border-2 border-[#674DB4] shadow-5xl";
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("projectby").style.opacity = "0";
            document.getElementById("bigcontainer").style.height = "80px";
          } else {
            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }
        }}
      >
        <h3 className="text-2xl text-[#F0E9E2] poppins ml-1 font-medium">
          {pathname == "Anime Self Care Kit" ? "Read to Relax" : pathname}
        </h3>
      </div>
    </Link>
  )

  const tracksLink = (
    <Link to="/tracks" className="flex items-center" id="trackslink">
      <Tooltip title="Tracks" arrow>
        <div
          className="w-11 flex items-center overflow-hidden cursor-pointer"
          onClick={() => {
            document.getElementById("lofiplayer").style.display = "display";
            document.getElementById("sounds").style.display = "none";
            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }}
        >
          <Button
            className="-mr-5 material-header-btn"
            style={{ marginLeft: "-20" }}
          >
            <CgHeadset className=" text-2xl text-[#F0E9E2]" />
          </Button>
        </div>
      </Tooltip>
    </Link>
  )

  const moodLink = (
    <Link to="/mood-mix" className="flex items-center" id="moodmixlink">
      <Tooltip title="Mood Mix" arrow>
        <div
          className="w-10 flex items-center overflow-hidden cursor-pointer"
          onClick={() => {
            document.getElementById("sounds").style.display = "block";
            document.getElementById("lofiplayer").style.display = "block";
            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }}
        >
          <Button className="-mr-5 material-header-btn">
            <SiAudiomack className="-mt-1 -ml-2 text-2xl text-[#F0E9E2]" />
          </Button>
        </div>
      </Tooltip>
    </Link>
  )

  const watchLink = (
    <Link
      to="/kind-anime-for-unkind-days"
      className="flex items-center"
      id="animelink"
    >
      <Tooltip title="Anime Recommendations" arrow>
        <div
          className="w-10 flex items-center overflow-hidden cursor-pointer"
          onClick={() => {
            document.getElementById("sounds").style.display = "none";
            document.getElementById("lofiplayer").style.display = "display";
            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }}
        >
          <Button
            className="-mr-5 material-header-btn"
            style={{ marginLeft: "-20" }}
          >
            <BsDisplay className=" text-2xl text-[#F0E9E2]" />
          </Button>
        </div>
      </Tooltip>
    </Link>
  )

  const focusLink = (
    <Link to="/focus" className="flex items-center" id="focuslink">
      <Tooltip title="Focus" arrow>
        <div
          className="w-11 flex items-center overflow-hidden cursor-pointer"
          onClick={() => {
            document.getElementById("lofiplayer").style.display = "block";
            document.getElementById("sounds").style.display = "none";
            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }}
        >
          <Button
            className="-mr-5 material-header-btn"
            style={{ marginLeft: "-20" }}
          >
            <BsCheckBox className=" text-2xl text-[#F0E9E2]" />
          </Button>
        </div>
      </Tooltip>
    </Link>
  )

  const careKitLink = (
    <Link
      to="/anime-self-care-kit"
      className="flex items-center"
      id="selfcarelink"
    >
      <Tooltip title="Read to Relax" arrow>
        <div
          className="w-11 flex items-center overflow-hidden cursor-pointer"
          onClick={() => {
            document.getElementById("lofiplayer").style.display = "block";
            document.getElementById("sounds").style.display = "none";
            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }}
        >
          <Button
            className="-mr-5 material-header-btn"
            style={{ marginLeft: "-20" }}
          >
            <HiOutlineBookOpen className=" text-2xl text-[#F0E9E2]" />
          </Button>
        </div>
      </Tooltip>
    </Link>
  )

  const starredLink = (
    <Link to="/stared-music" className="flex items-center" id="staredlink">
      <Tooltip title="Favorites" arrow>
        <div
          className="w-10 flex items-center overflow-hidden cursor-pointer"
          onClick={() => {
            document.getElementById("lofiplayer").style.display = "block";
            document.getElementById("sounds").style.display = "none";
            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }}
        >
          <Button className="-mr-5 material-header-btn">
            <BsStar className="-mt-1 -ml-2 text-2xl text-[#F0E9E2]" />
          </Button>
        </div>
      </Tooltip>
    </Link>
  )

  const mentalHealthLink = (
    <Link
      to="/mental-health-resources"
      className="flex items-center"
      id="animetherapylink"
    >
      <Tooltip title="Mental Health Resources" arrow>
        <div
          className="w-11 flex items-center overflow-hidden cursor-pointer"
          onClick={() => {
            document.getElementById("lofiplayer").style.display = "block";
            document.getElementById("sounds").style.display = "none";

            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }}
        >
          <Button
            className="-mr-5 material-header-btn"
            style={{ marginLeft: "-20" }}
          >
            <FiPhone className="text-2xl text-[#F0E9E2]" />
          </Button>
        </div>
      </Tooltip>
    </Link>
  )

  const shortcutLink = (
    <Link
      to="/keyboard-shortcuts"
      className="flex items-center"
      id="keyboardlink"
    >
      <Tooltip title="Keyboard Shortcuts" arrow>
        <div
          className="w-11 flex items-center overflow-hidden cursor-pointer"
          onClick={() => {
            document.getElementById("sounds").style.display = "none";

            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }}
        >
          <Button
            className="-mr-5 material-header-btn"
            style={{ marginLeft: "-20" }}
          >
            <GoKeyboard className=" text-[2.1em] text-[#F0E9E2]" />
          </Button>
        </div>
      </Tooltip>
    </Link>
  )

  const twitterTooltip = (
    <Tooltip title="Share to Twitter" arrow>
      <a href={twitterLink} target="_blank" rel="noreferrer">
        <div
          className="flex bg-black bg-opacity-100 border rounded-md p-2"
          style={{ background: "#1A91DA", alignItems: "center" }}
        >
          <div className="pr-4">
            <FiTwitter size={"24"} />
          </div>
          <p className="text-xs">Share</p>
        </div>
      </a>
    </Tooltip>
  )

  const faqLink = (
    <Link to="/faq" className="flex items-center" id="faqlink">
      <Tooltip title="FAQ" arrow>
        <div
          className="w-11 flex items-center overflow-hidden -mr-1"
          onClick={() => {
            document.getElementById("bigcontainer").className =
              "relative h-[90%] w-[90%] rounded-xl overflow-hidden animate__animated animate__fadeInDown shadow-2xl bg-cover bg-center border-2 border-[#674DB4] shadow-5xl";
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
            document.getElementById("sounds").style.display = "none";

            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }}
        >
          <Button className="material-header-btn">
            <BsQuestionCircle className=" text-2xl text-[#F0E9E2]" />
          </Button>
        </div>
      </Tooltip>
    </Link>
  )

  const fullscreenLink = (
    <Link to="/" className="flex items-center">
      <Tooltip title="Full Screen" arrow>
        <div
          className="w-10 flex items-center overflow-hidden"
          onClick={() => {
            if (screenfull.isEnabled) {
              screenfull.request();
            }

            if (!isFullScreen) {
              setIsFullScreen(!isFullScreen);
              document.getElementById("bigcontainer").className =
                "absolute h-[8%] w-[90%] rounded-xl animate__animated animate__fadeInDown shadow-2xl bg-cover bg-center border-2 border-[#674DB4] shadow-5xl bigcontainer";
              document.getElementById("bigcontainer").style.top = "9.5%";
              document.getElementById("projectby").style.opacity = "0";
              document.getElementById("bigcontainer").style.height = "80px";
              document.getElementById("trackslink").style.display = "block";
              document.getElementById("moodmixlink").style.display =
                "block";
              document.getElementById("animelink").style.display = "block";
              document.getElementById("focuslink").style.display = "block";
              document.getElementById("selfcarelink").style.display =
                "block";
              document.getElementById("staredlink").style.display = "block";
              document.getElementById("keyboardlink").style.display =
                "block";
              document.getElementById("faqlink").style.display = "block";
            } else {
              try {
                document.exitFullscreen();
                setIsFullScreen(!isFullScreen);
                document.getElementById("bigcontainer").className =
                  classname;
                document.getElementById("bigcontainer").style.top = "0%";
                document.getElementById("bigcontainer").style.height = "";
                document.getElementById("projectby").style.opacity = "100";
                document.getElementById("trackslink").style.display =
                  "block";
                document.getElementById("moodmixlink").style.display =
                  "block";
                document.getElementById("animelink").style.display =
                  "block";
                document.getElementById("focuslink").style.display =
                  "block";
                document.getElementById("selfcarelink").style.display =
                  "block";
                document.getElementById("staredlink").style.display =
                  "block";
                document.getElementById("keyboardlink").style.display =
                  "block";
                document.getElementById("faqlink").style.display = "block";
              } catch (err) {
                console.error(err);
              }
            }
          }}
        >
          <Button className="material-header-btn">
            {isFullScreen ? (
              <BsFullscreenExit className=" text-2xl text-[#F0E9E2]" />
            ) : (
              <BsArrowsFullscreen className=" text-2xl text-[#F0E9E2]" />
            )}
          </Button>
        </div>
      </Tooltip>
    </Link>
  )

  return (
    <div
      className={
        "lg:flex w-full h-min items-center justify-between animate__animated animate__fadeIn z-[1000] flex-row sm:block header"
      }
    >
      <div
        className="flex flex-col justify-center lg:items-center sm:items-start h-full ml-5 cursor-pointer"
        onClick={() => {
          document.getElementById("sounds").style.display = "none";

          document.getElementById("lofiplayer").style.display = "block";

          if (
            isFullScreen &&
            document.URL.substr(document.URL.length - 1) === "/"
          ) {
            document.getElementById("bigcontainer").className =
              "absolute h-[8%] w-[90%] rounded-xl animate__animated animate__fadeInDown shadow-2xl bg-cover bg-center border-2 border-[#674DB4] shadow-5xl";
            document.getElementById("bigcontainer").style.top = "9.5%";
            document.getElementById("projectby").style.opacity = "0";
            document.getElementById("bigcontainer").style.height = "90%";
          } else {
            document.getElementById("bigcontainer").className = classname;
            document.getElementById("bigcontainer").style.top = "0%";
            document.getElementById("bigcontainer").style.height = "";
            document.getElementById("projectby").style.opacity = "100";
          }
        }}
      >
      {homeLink}
      </div>
      <div className="flex items-center mr-5 justify-center">
        {tracksLink}
        {moodLink}
        {!isConferenceApp ? watchLink : (<></>)}
        {!isConferenceApp ? focusLink : (<></>)}
        {careKitLink}
        {!isConferenceApp ? starredLink : (<></>)}
        {!isConferenceApp ? mentalHealthLink : (<></>)}
        {!isConferenceApp ? shortcutLink : (<></>)}
        {faqLink}
        {!isConferenceApp ? fullscreenLink : (<></>)}

        <Tooltip title="Donate" className="p-4" arrow>
          <a href="https://donorbox.org/anime-self-care?default_interval=o">
            <div
              className="flex bg-black bg-opacity-100 border rounded-md p-2"
              style={{ background: "#000000", alignItems: "center" }}
            >
              <div className="pr-4">
                <img
                  style={{ minWidth: "24px" }}
                  src="https://donorbox.org/images/red_logo.png"
                  alt="donar box logo"
                />
              </div>
              <p className="text-xs">Donate</p>
            </div>
          </a>
        </Tooltip>

        {!isConferenceApp ? twitterTooltip : (<></>)}
      </div>
    </div>
  );
};

export default Header;
