import React from "react";
import TabWrapper from "../components/TabWrapper";

const Faq = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="flex items-center pt-5 flex-col my-3 h-full w-full overflow-y-scroll">
      <TabWrapper
        expanded={expanded}
        handleChange={handleChange}
        panelNo="panel0"
        name="Is the website down or is it just me?"
      >
        For info and updates about the websites status visit:{" "}
        <a
          className="links"
          href="https://status.animeselfcare.org/"
          target="_blank"
          rel="noreferrer"
        >
          status.animeselfcare.org
        </a>{" "}
        or our twitter:{" "}
        <a
          className="links"
          href="https://twitter.com/anime_self_care"
          target="_blank"
          rel="noreferrer"
        >
          @anime_self_care
        </a>
      </TabWrapper>
      <TabWrapper
        expanded={expanded}
        handleChange={handleChange}
        panelNo="panel1"
        name="What is Anime Self Care?"
      >
        AnimeSelfCare.org is your place for calm, focus and productivity using
        anime. Enjoy some relaxing anime tunes, download your favorite Anime
        Self Care Kit and create your perfect sound environment.
      </TabWrapper>
      <TabWrapper
        expanded={expanded}
        handleChange={handleChange}
        panelNo="panel2"
        name="About Anime For Humanity"
      >
        Anime Self Care is a project brought to you by Anime For Humanity a a
        501c(3) non-profit charity dedicated to using anime, manga and cosplay
        as a medium to challenge the stigma surrounding mental health.
      </TabWrapper>
      <TabWrapper
        expanded={expanded}
        handleChange={handleChange}
        panelNo="panel3"
        name="Do I need a subscription to use the website?"
      >
        No. The website is FREE. FOREVER!
      </TabWrapper>
      <TabWrapper
        expanded={expanded}
        handleChange={handleChange}
        panelNo="panel4"
        name="How do I navigate the website?"
      >
        <p>
          <b>Step 1:</b> Download your favorite Anime Self Care Kit. <br />
          <b>Step 2:</b> Pick a radio station of your choice. <br />
          <b>Step 3:</b> Create a unique sound atmosphere to immerse yourself in
          deep sleep, improve productivity or reduce stress and anxiety. You can
          layer sounds one after another, or click the recommended mood mix.
          <br />
          <b>Step 4:</b> Use the to-do list to keep track of your self care
          plan. (You can use it for your daily life tasks as well) <br />
          <b>Step 5:</b> Enjoy and share it with your loved ones! If you have
          any questions or concerns, shoot us a message on Discord.
        </p>
      </TabWrapper>
      <TabWrapper
        expanded={expanded}
        handleChange={handleChange}
        panelNo="panel5"
        name="My favorite anime is not listed in the Self Care library, where do I submit it?"
      >
        Absolutely! We are always looking to improve the website and grow our
        library of Anime Self Care Kits. Please click on the FEEDBACK icon to
        share your ideas, feedback and any anime you would like us to create a
        kit for.
      </TabWrapper>
      <TabWrapper
        expanded={expanded}
        handleChange={handleChange}
        panelNo="panel6"
        name="How can I get involved?"
      >
        Thank you for your interest in supporting Anime For Humanity! We are
        always looking for artists to help us create more Anime Self Care Kits.
        If you would like to be involved and raise awareness for mental health
        through your artwork and anime, please fill up this
        <a
          href="http://afh.moe/artist"
          className="underline font-semibold ml-1"
          target="_blank"
          rel="noreferrer"
        >
          form
        </a>
        . If you are not an artist but want to volunteer with us, please click
        <a
          href="http://animeforhumanity.org/donate"
          className="underline font-semibold ml-1"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
      </TabWrapper>
      <TabWrapper
        expanded={expanded}
        handleChange={handleChange}
        panelNo="panel7"
        name="Credits"
      >
        Big shout out to all the artists, web devs, therapists and volunteers
        who made this project possible. We couldn't do it without{" "}
        <a
          href="http://animeforhumanity.org/credits"
          className="underline font-semibold ml-1"
          target="_blank"
          rel="noreferrer"
        >
          you!
        </a>
      </TabWrapper>
      <TabWrapper
        expanded={expanded}
        handleChange={handleChange}
        panelNo="panel8"
        name="Privacy | Terms & Conditions"
      >
        Click Here:{" "}
        <a
          className="links"
          href="http://terms.animeselfcare.org/"
          target="_blank"
          rel="noreferrer"
        >
          terms.animeselfcare.org
        </a>
      </TabWrapper>
    </div>
  );
};

export default Faq;
