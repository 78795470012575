import React from "react";
import { Button } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom"; // react router

// icons
import { HiOutlineBookOpen } from "react-icons/hi";
import { SiAudiomack } from "react-icons/si";
import { CgHeadset } from "react-icons/cg";
import { BiHeartSquare } from "react-icons/bi";

const ConferenceHome = () => {
  return (
    <div className="flex flex-col lg:pb-[10%] lg:justify-between sm:justify-end-[85%] lg:h-full "
    style={{ justifyContent: "center" }}
    >
      <div id="home_list" className="flex flex-col h-[100%] items-center overflow ">
        {/*PAGE TITLE*/}
        <h1 className="text-4xl text-center">What would you like to do?</h1>

        {/*PAGE LINKS*/}
        <div
          className="w-full lg:flex text-center mt-10"
          style={{ 
            justifyContent: "center",
            display: "grid",
            "grid-template-columns": "50% 50%"
          }}
        >
          <Link to="/tracks">
            <div className={"w-full lg:w-[30%] flex flex-col items-center  mb-8"}>
              <CgHeadset className="text-[#F0E9E2]" size="9em" />
              <h1 className={"text-lg"} style={{ maxWidth: "240px" }}>
                TRACK MIX
              </h1>
              <h2 className={"text-lg"} style={{ maxWidth: "240px" }}>
                Listen To Relaxing LoFi, Classical & Anime Tunes
              </h2>
            </div>
          </Link>

          <Link to="/mood-mix">
            <div className={"w-full lg:w-[30%] flex flex-col items-center  mb-8"}>
              <SiAudiomack className="text-[#F0E9E2]" size="9em" />
              <h1 className={"text-lg"} style={{ maxWidth: "240px" }}>
                MOOD MIX
              </h1>
              <h2 className={"text-lg"} style={{ maxWidth: "240px" }}>
                Unwind with Nature Sounds & Anime Backgrounds
              </h2>
            </div>
          </Link>

          <Link to="/anime-self-care-kit">
            <div className={"w-full lg:w-[30%] flex flex-col items-center  mb-8"}>
              <HiOutlineBookOpen className="text-[#F0E9E2]" size="9em" />
              <h1 className={"text-lg"} style={{ maxWidth: "240px" }}>
                READ TO RELAX
              </h1>
              <h2 className={"text-lg"} style={{ maxWidth: "240px" }}>
                A Collection of Fanmade Anime Short Stories
              </h2>
            </div>
          </Link>

          <div className={"w-full lg:w-[30%] flex flex-col items-center  mb-8"}>
            <a href="https://donorbox.org/anime-self-care?default_interval=o">
              <BiHeartSquare className="text-[#F0E9E2]" size="9em" style={{ display: "inline" }} />
              <h1 className={"text-lg"} style={{ maxWidth: "240px" }}>
                DONATE
              </h1>
              <h2 className={"text-lg"} style={{ maxWidth: "240px" }}>
                Support the Anime Self Care
              </h2>
            </a>
          </div>
        </div>
      </div>

      {/*where lofiplayer was */}
      {/* POWR.io Popup */}
      <div className="powr-popup" id="74bd4bd3_1639272310"></div>
    </div>
  );
};

export default ConferenceHome;
