import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@material-ui/core";
import { BsDownload, BsArrow90DegDown } from "react-icons/bs";

const Item = ({ item }) => {
  const [downloadCount, setDownloadCount] = useState(0);

  useEffect(() => {
    if (item.htmlUrl) {
      const url = new URL(item.htmlUrl);

      var xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `https://api.countapi.xyz/hit/animeselfcare.org/${url.pathname.substring(
          1
        )}`
      );
      xhr.responseType = "json";
      xhr.onload = function () {
        setDownloadCount(this.response.value);
      };
      xhr.send();
    }
  }, []);

  return (
    <div
      className="relative flex flex-col items-center justify-center overflow-hidden animate__animated animate__fadeInUp"
      id="pdf-download"
    >
      <Button className="track">
        <div className="flex justify-center" style={{ fontSize: "15px" }}>
          <BsArrow90DegDown style={{ fontSize: "30px" }} /> Look Inside!
        </div>
        <iframe
          allowfullscreen="true"
          scrolling="no"
          className="fp-iframe"
          title="pdf-flipbook"
          style={{
            width: "500px",
            height: "360px",
          }}
          src={item.htmlUrl}
        ></iframe>
      </Button>

      <div className="flex items-center justify-center w-full">
        <a href={item.pdfUrl} className="trigger-popup w-full text-right font-light mt-1 flex items-center justify-center download-footer" download>
          <BsDownload className="trigger-popup downloadbtn" />
          <span className="trigger-popup ml-1 text-lg">DOWNLOAD</span>
        </a>
      </div>
    </div>
  );
};

const Kits = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    await axios
      .get("/data/kits.json", {
        headers: {},
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{
      marginTop: "25px",
      paddingBottom: "200px"
    }} className="flex h-full items-center justify-center flex-wrap overflow-scroll">
      {data && data.map((item, _) => <Item item={item} />)}
    </div>
  );
};

export default Kits;
