export const therapyResources = [
  {
    name: "Suicide Prevention",
    id: "1",
    image_name: "SuicidePrevention",
    description: "American Crisis support and suicide prevention.",
    hours: "24/7",
    languages: "English, Spanish",
    hotline: "1-800-273-8255",
    website: "https://suicidepreventionlifeline.org/",
    website_name: "suicidepreventionlifeline.org",
  },
  {
    name: "Crisis Text Line",
    id: "2",
    image_name: "Crisis",
    description: "Crisis Text Line provides free 24/7 support via text message",
    hours: "24/7",
    languages: "English, Spanish",
    hotline: "Text HOME to 741741",
    website: "https://www.crisistextline.org",
    website_name: "crisistextline.org",
  },
  {
    name: "Trevor Project",
    id: "3",
    image_name: "Trevor",
    description: "Support and suicide prevention for LGBTQ+ youth under 25",
    hours: "24/7",
    languages: "English, Spanish",
    hotline: "1-866-488-7386",
    website: "https://www.thetrevorproject.org/",
    website_name: "thetrevorproject.org",
  },
  {
    name: "RAINN",
    id: "4",
    image_name: "Rainn",
    description: "National Sexual Assault Hotline. Crisis chat is available",
    hours: "24/7",
    languages: "English, Spanish",
    hotline: "1-800-656-4673",
    website: "https://www.rainn.org/",
    website_name: "rainn.org",
  },
];