import React, { useEffect, useState } from "react";

import { Button } from "@material-ui/core"; // material-ui

// react router
import { useHistory } from "react-router-dom";
import { BsStar, BsStarFill } from "react-icons/bs";
import { animeRec } from "../animeData";

const Anime = ({
  animeId,
  title,
  fileName,
  animeLink,
  staredMusic,
  setStaredMusic,
  fetchVideo,
  showInOneLine,
}) => {
  const [isStared, setIsStared] = useState(false, "");
  let history = useHistory();

  const updateVideo = () => {
    if (typeof window !== undefined) {
      const video = {
        videoId: animeId,
        name: title,
        type: "anime",
        fileName: fileName,
        animeLink: animeLink,
      };
      localStorage.setItem("video", JSON.stringify(video));
    }
    document.getElementById("lofiplayer").style.display = "block";

    fetchVideo();
  };
  const fetchStaredMusic = () => {
    if (staredMusic.some((music) => music.videoId === animeId)) {
      setIsStared(true);
    } else {
      setIsStared(false);
    }
  };

  useEffect(() => {
    fetchStaredMusic();
  });

  const addToStaredMusic = () => {
    if (typeof window !== undefined) {
      if (isStared) {
        setStaredMusic(
          staredMusic.filter((music) => music.videoId !== animeId)
        );
      } else {
        const video = {
          videoId: animeId,
          name: title,
          type: "anime",
          fileName: fileName,
          animeLink: animeLink,
        };
        setStaredMusic([...staredMusic, video]);
      }
    }
  };
  return (
    <div className="relative rounded-lg flex items-center flex-col justify-center w-auto my-1 mr-1 ml-5 shadow-2xl overflow-hidden animate__animated animate__fadeInUp">
      <Button className="track">
        
        {/* FAVORITE STAR */}
        <div
          className="absolute top-0 rounded-sm right-[2px] z-10 bg-[#333] p-[6px] border border-[#666] text-[#ccc] hover:text-[#fff]"
          onClick={addToStaredMusic}
        >
          {isStared ? (
            <BsStarFill className="text-lg" />
          ) : (
            <BsStar className="text-lg" />
          )}
        </div>

        {/* IMAGE & LINK */}
        <a href={animeLink} rel="noopener noreferrer" target="_blank">
          <img
            className={`rounded-md h-[125px] w-[250px] relative ${title}-image  `}
            key={animeId}
            src={`/img/AnimeRec/${fileName}.jpg`}
            alt={title}
            title={title}
            onClick={updateVideo}
          />
        </a>
      </Button>

      {/* ANIME TITLE */}
      <h1
        className={
          showInOneLine
            ? "text-md font-light mt-1 w-[250px] text-center line-hidden"
            : "text-md font-light mt-1 w-[250px] text-center"
        }
      >
        {title}
      </h1>
    </div>
  );
};
export default Anime;
