import React, { useState } from 'react'
import { IoIosClose } from "react-icons/io";
import { BsQuestion } from "react-icons/bs"

const FaqButton = ({children}) => {

  // const [showModal, setShowModal] = useState(false);

  // const handleShow = () =>{
  //   showModal ? setShowModal(false) : setShowModal(true);
  // } 

  // const handleHide = () =>{
  //   setShowModal(false);
  // }

  // const modalClass = showModal ? "modal-show" : "modal-hide";

  return (
    <>
      <button
        className="trigger-popup faq_button absolute bg-gradient-to-t from-[#674DB4] to-[#A34FA7] bottom-5 right-4"
        // onClick={handleShow}
      >
        <BsQuestion className="trigger-popup" color={'white'} size={42}/>
      </button>
      {/* {}
      <div className={` ${modalClass} modal  bg-gradient-to-t from-[#674DB4] to-[#A34FA7] absolute animate__animated animate__fadeInUp z-[10]`}>
        <div className="flex justify-between  items-center">
          <h2 className={'text-white'}>Faq</h2><a href="javascript:;" onClick={handleHide}> <IoIosClose color={'white'}/> </a>
        </div>
        <div className="m-2 text-xs">
          {children}
        </div>
      </div> */}
      {/* {children} */}
    </>
  );
}

export default FaqButton;