const sounds = [
  {
    id: 1,
    name: "Rain",
  },
  {
    id: 2,
    name: "Thunderstorm",
  },
  {
    id: 3,
    name: "Snow",
  },
  {
    id: 4,
    name: "Campfire",
  },
  {
    id: 6,
    name: "Birds",
  },
  {
    id: 8,
    name: "Rowing",
  },
  {
    id: 9,
    name: "Wind",
  },
  {
    id: 10,
    name: "Leaves",
  },
  {
    id: 12,
    name: "Cafe",
  },
  {
    id: 14,
    name: "Farm",
  },
  {
    id: 15,
    name: "Keyboard",
  },
  {
    id: 16,
    name: "Ocean",
    soundLink: "https://e8b8e3q2.ssl.hwcdn.net/sounds/hipster/ocean.mp3",
  },
  {
    id: 17,
    name: "Library",
  },
  {
    id: 18,
    name: "River",
    soundLink: "https://e8b8e3q2.ssl.hwcdn.net/sounds/river160.mp3",
  },
  {
    id: 19,
    name: "Night",
  },
  {
    id: 20,
    name: "Road",
    soundLink: "https://e8b8e3q2.ssl.hwcdn.net/sounds/hipster/highway160.mp3",
  },
  {
    id: 21,
    name: "Fan",
    soundLink: "https://e8b8e3q2.ssl.hwcdn.net/sounds/objects/fan-two160.mp3",
  },
  {
    id: 22,
    name: "Windchime",
    soundLink: "https://e8b8e3q2.ssl.hwcdn.net/sounds/windchime160.mp3",
  },
  {
    id: 23,
    name: "Train",
    soundLink: "https://e8b8e3q2.ssl.hwcdn.net/sounds/hipster/train160.mp3",
  },
  {
    id: 24,
    name: "Cat",
    soundLink: "https://e8b8e3q2.ssl.hwcdn.net/sounds/cat160.mp3",
  },
];

export default sounds;
