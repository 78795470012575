import React, { useState } from "react";
import { animeRec } from "../animeData";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsStar, BsStarFill } from "react-icons/bs";
import Anime from "../components/Anime";
import AnimeSwiper from "../components/AnimeSwiper";
SwiperCore.use([Navigation]);
const AnimeRecommendations = ({ staredMusic, setStaredMusic, fetchVideo }) => {
  const [isStared, setIsStared] = useState("");

  return (
    <div className="h-full w-full pb-[30%] lg:pb-[10%] overflow-scroll">
      {animeRec.map((group) => (
        <div
          style={{ marginTop: "30px" }}
          className="flex  justify-center w-full my-3"
        >
          <div
            className="flex flex-wrap flex-row w-full justify-center mt-5"
            style={{ gap: "25px" }}
          >
            {/* SECTION TITLE */}
            <h1 className="w-[80%] text-2xl mt-5 -mb-5">
              {group.group}
            </h1>
            
            <AnimeSwiper
              staredMusic={staredMusic}
              setStaredMusic={setStaredMusic}
              fetchVideo={fetchVideo}
              animes={group.animes}
              swiperName={group.groupId}
              navigations={{
                prevEl: group.prevPointer,
                nextEl: group.nextPointer,
              }}
            />
          </div>
        </div>
      ))}
      {/* POWR.io Popup */}
      <div className="powr-popup" id="2fd93b5b_1639286514"></div>
    </div>
  );
};

export default AnimeRecommendations;
