import React from "react";
import { therapyResources } from "../therapyResources";
import { BiTime } from "react-icons/bi";
import { IoLanguageOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { MdLanguage } from "react-icons/md";

const AnimeTherapy = () => {
  return (
    <div
      className="h-full w-full pb-[30%] lg:pb-[10%] overflow-scroll flex flex-col "
      style={{ marginTop: "65px" }}
    >
      <div
        className="flex flex-wrap w-full justify-center"
        style={{ gap: "25px" }}
      >
        {therapyResources.map((resource) => (
          <div className="anime_therapy flex items-center justify-center flex-col rounded-lg bg-gradient-to-t from-[#674DB4] to-\[\#333\] shadow-2xl ">
            <a
              href={resource.website}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className={`relative ${resource.name}-image`}
                key={resource.id}
                src={`/img/TherapyResources/${resource.image_name}.jpg`}
                alt={resource.name}
                title={resource.name}
              />
            </a>
            <h2 className="text-2xl m-2">{resource.name}</h2>
            <hr />
            <h3 className="font-normal m-2">{resource.description}</h3>
            <ul
              className="flex flex-col justify-center w-full p-\[2px\]"
              style={{ border: "1px dashed #333", "border-radius": "5px" }}
            >
              <li className="text-sm m-2">
                <BiTime className="anime_therapy-logo mr-2" />
                {resource.hours}
              </li>
              <li className="text-sm m-2">
                <IoLanguageOutline className="anime_therapy-logo mr-2" />
                {resource.languages}
              </li>
              <li className="text-sm m-2">
                <FiPhone className="anime_therapy-logo mr-2" />
                {resource.hotline}
              </li>
              <li className="text-sm m-2">
                <MdLanguage className="anime_therapy-logo mr-2" />
                <a
                  href={resource.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {resource.website_name}
                </a>
              </li>
            </ul>
          </div>
        ))}
      </div>
      <div className="flex w-full justify-center" style={{ marginTop: "65px" }}>
        <h1 className="text-xl">
          Click here for more:{" "}
          <a
            href="https://www.findhelp.org/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#674DB4" }}
          >
            findhelp.org
          </a>
        </h1>
      </div>
    </div>
  );
};

export default AnimeTherapy;
