import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { BsMusicNoteBeamed } from "react-icons/bs"


const PlayerButton = ({ children }) => {
  const [showModal, setShowModal] = useState(true);

  const handleShow = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };

  const handleHide = () => {
    setShowModal(false);
  };

  const modalClass = showModal ? "player-show" : "player-hide";

  return (
    <>
      <button
        id="player_button"
        className="player_button bg-gradient-to-t from-[#674DB4] to-[#A34FA7] absolute bottom-5"
        onClick={handleShow}
      >
        <BsMusicNoteBeamed color={'white'} size={28}></BsMusicNoteBeamed>
      </button>
      <div
        id="player-container"
        className={` ${modalClass} player-modal absolute animate__animated animate__fadeInUp z-[1] `}
      >
        <div className="flex ">
          {children}
          <a href="javascript:;" onClick={handleHide} id="close_player">
            {" "}
            <IoIosClose color={'white'}/>
          </a>
        </div>
      </div>
    </>
  );
};

export default PlayerButton;
